import NumberOneIcon from '@app/icons/№1.svg';
import BankIcon from '@app/icons/bank-icon.svg';
import SkolkovoIcon from '@app/icons/sk.svg';

import RatingIcon from '@app/icons/ruBB+.svg';

export enum AchievementType {
    RATING = 'rating',
    NUMBER_ONE = 'number_one',
    CENTRAL_BANK = 'central_bank',
    SKOLKOVO = 'skolkovo',
}

interface IAchievementConfig {
    url: string;
    text: string;
    icon: string;
}

export const ACHIEVEMENTS_MAP: Record<AchievementType, IAchievementConfig> = {
    [AchievementType.NUMBER_ONE]: {
        url: 'https://www.kommersant.ru/doc/5826253?utm_source=yxnews&utm_medium=desktop',
        text: 'Первое место среди инвестиционных платформ',
        icon: NumberOneIcon,
    },
    [AchievementType.CENTRAL_BANK]: {
        url: 'https://www.cbr.ru/finorg/foinfo/?ogrn=1187746779868',
        text: 'Регулируется ЦБ РФ',
        icon: BankIcon,
    },
    [AchievementType.SKOLKOVO]: {
        url: 'https://navigator.sk.ru/orn/1123480',
        text: '',
        icon: SkolkovoIcon,
    },
    [AchievementType.RATING]: {
        url: 'https://raexpert.ru/releases/2023/dec20e',
        text: 'Рейтинг',
        icon: RatingIcon,
    },
};