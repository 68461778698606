'use client';

import {
    useCallback,
    useState,
} from 'react';
import styles from './MainPageMainSection.module.scss';
import Section from '../../../server/ui/Section/Section';
import Image from '../../../server/Image/Image';
import HtmlMarkup from '@app/components/server/common/HtmlMarkup/HtmlMarkup';
import { AchievementType } from '@app/components/client/common/FormAchievements/FormAchievment/FormAchievement.helpers';
import FormAchievement from '@app/components/client/common/FormAchievements/FormAchievment/FormAchievement';
import { IStatisticsBlockApiModel } from '@app/models/mainPage/mainPage.';
import JivoChatMobileAnimation from '../../common/JivoChatMobileAnimation/JivoChatMobileAnimation';

import StatisticBlock from './StatisticBlock/StatisticBlock';

interface IProps {
    title: string;
    description: string;
    statisticBlocks: IStatisticsBlockApiModel[];
}

const MainPageMainSection: React.FC<IProps> = props => {
    const {
        title,
        description,
        statisticBlocks,
    } = props;

    const [loaded, setLoaded] = useState(false);

    const didLoaded = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <JivoChatMobileAnimation />

            <div className={styles['desktop-view']}>
                <Section animation="none" filled={false} stretch={['vertically', 'horizontally']}>
                    <div className={styles['container']}>
                        <div className={styles['main-info']}>
                            <HtmlMarkup tag="h1" className={styles['title']} markup={title} />
                            <div className={styles['main-info__subsection']}>
                                <HtmlMarkup tag="p" className={styles['main-info__description']} markup={description} />
                                <div className={styles['achievements']}>
                                    <FormAchievement className={styles['achievement']} achievement={AchievementType.SKOLKOVO} wideIcon skolkovo />
                                    <FormAchievement className={styles['achievement']} achievement={AchievementType.RATING} wideIcon />
                                    <FormAchievement className={styles['achievement']} achievement={AchievementType.NUMBER_ONE} wide />
                                    <FormAchievement className={styles['achievement']} achievement={AchievementType.CENTRAL_BANK} />
                                </div>
                            </div>
                        </div>
                        <div className={styles['background-image']}>
                            <div className={styles['image-wrapper']}>
                                <Image
                                    className={styles['image']}
                                    src="/assets/main-page-background.png"
                                    alt=""
                                    fill
                                    onLoad={didLoaded}
                                    quality={loaded ? 100 : 10}
                                    priority
                                />
                            </div>
                        </div>
                        <StatisticBlock statisticBlocks={statisticBlocks} />
                    </div>
                </Section>
            </div>
        </>
    );
};

export default MainPageMainSection;
